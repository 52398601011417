<template>
  <section class="antar-barang">
    <div class="container-scroller">
      <div class="page-header">
        <h3 class="page-title">
          &nbsp;
        </h3>
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><router-link class="nav-link" to="/"><span class="menu-title">Dashboard</span></router-link></li>
            <li class="breadcrumb-item">. . .</li>
            <li class="breadcrumb-item next-breadcrumb" aria-current="page"><router-link class="nav-link" :to="`/operasional/antar-barang/detail-paket/${params.uuid}`"><span class="menu-title">Antar Barang - Detail Paket</span></router-link></li>            
            <li class="breadcrumb-item next-breadcrumb" aria-current="page"><router-link class="nav-link" :to="`/operasional/antar-barang/pengukuran-paket/${params.uuid}`"><span class="menu-title">Antar Barang - Pengukuran Paket</span></router-link></li>            
            <li class="breadcrumb-item active" aria-current="page">Pengiriman Paket</li>						
          </ol>
        </nav>
      </div>          
      <div class="container-fluid page-body-wrapper full-page-wrapper">
        <div class="content-wrapper d-flex align-items-center auth">
          <div class="row w-100 flex-grow">
            <h2 class="col-xl-8 col-lg-6 mx-auto text-center pb-5" style="color: #00b050;font-weight: 800;">Antar Barang</h2>            
            <div class="col-xl-8 col-lg-6 mx-auto">
              <div class="auth-form-light text-left p-5">
                <div class="card pb-4">
									<div class="brand-logo text-center">
										<img class="pt-5" src="@/assets/images/kebut/XMLID_1147_.png">
									</div>
									<div class="row pl-4">
										<h6 class="font-weight-bold col-lg-12">Berat Maksimal Paket : 25kg</h6>
										<h6 class="font-weight-bold col-lg-12">Batas Ukuran Paket : 60x60x60</h6>
										<h6 class="font-weight-bold col-lg-12">Gratis Waktu Tunggu : 15 Menit</h6>
									</div>																											        
                </div>

                <form class="pt-3">
                  <div class="row">
                    <div class="col-lg-6">                      
                      <div class="form-group">
                        <b-row>
                          <b-col lg="12"><label>Berat Barang</label></b-col>
                          <b-col lg="12"><h6> {{ `${data.weight} ${data.units_of_measurements_name}` }}</h6></b-col>
                        </b-row>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-12">                      
                      <div class="form-group">
                        <b-row>
                          <b-col lg="1">
                            <i class="mdi mdi mdi mdi-arrow-up-drop-circle btn-icon-append" style="color: #00b050"></i>
                          </b-col>
                          <b-col class="pt-0" lg="11">
                            <label class="mb-0 mt-1">Pengirim</label>
                            <h6 class="pl-0 col-lg-12">{{ data.name_sender }}</h6>
                            <h6 class="pl-0 col-lg-12">{{ data.telp_sender }}</h6>
                            <p class="pl-0 col-lg-12">{{ data.address_sender }}</p>                            
                          </b-col>                          
                        </b-row>
                        <b-row>
                          <b-col lg="1">
                            <i class="mdi mdi mdi mdi-arrow-down-drop-circle btn-icon-append" style="color: #e91e63"></i>
                          </b-col>
                          <b-col class="pt-0" lg="11">
                            <label class="mb-0 mt-1">Penerima</label>
                            <h6 class="pl-0 col-lg-12">{{ data.name_recipient }}</h6>
                            <h6 class="pl-0 col-lg-12">{{ data.telp_recipient }}</h6>
                            <p class="pl-0 col-lg-12">{{ data.address_recipient }}</p>                            
                          </b-col>                          
                        </b-row>                        
                      </div>
                    </div>
                  </div>
                  
                  <div class="form-group">
                    <b-row>
                      <template v-if="data.uuid_time_units == 'oKUN2mFbhlmuVbJ'">
                        <b-col lg="12"><h6>Waktu Tunggu : 00 jam {{ data.load_time_value }} menit</h6></b-col>
                      </template>
                      <template v-else-if="data.uuid_time_units == 'AAkoAYQzLXS2LrH'">
                        <b-col lg="12"><h6>Waktu Tunggu : {{ data.load_time_value }} jam 00 menit</h6></b-col>                        
                      </template>
                    </b-row>
                  </div>
                  
                  <div class="row">
                    <div class="col-lg-4">                      
                      <div class="form-group">
                        <b-row>
                          <b-col lg="12"><label>Deskripsi Barang</label></b-col>
                        </b-row>
                        <h6>{{ data.desc_package }}</h6>
                      </div>
                    </div>
                    <div class="col-lg-6">                      
                      <div class="form-group">
                        <b-row>
                          <b-col lg="12"><label>Ukuran</label></b-col>
                        </b-row>
                        <h6>{{ data.package_size_name }}</h6>
                      </div>
                    </div>
                    <div class="col-lg-12">                      
                      <div class="form-group">
                        <b-row>
                          <b-col lg="6"><h6>Waktu Tunggu + menit</h6></b-col>
                          <b-col lg="6"><h6 class="pull-right">Rp. {{ data.price_time }}</h6></b-col>                          
                        </b-row>
                        <b-row>
                          <b-col lg="6"><h6>Ongkos Antar</h6></b-col>
                          <b-col lg="6"><h6 class="pull-right">Rp. {{ data.price_package }}</h6></b-col>                          
                        </b-row>
                        <b-row>
                          <b-col lg="6"><h6>Total</h6></b-col>
                          <b-col lg="6"><h6 class="pull-right">Rp. {{ data.total_price  }}</h6></b-col>                          
                        </b-row>                                                
                      </div>
                    </div>
                  </div>    

                  <div class="form-group">
                    <b-row class="mt-2">
                      <b-col lg="6"><label>Metode Pembayaran</label></b-col>
                      <!-- <b-col lg="6" style="text-align: right;"><label>* required</label></b-col>                                        -->
                    </b-row>
                    <v-select v-model="optSelect" :options="dataOption1"></v-select>
                  </div>                                

                  <div class="mt-3">
                    <template v-if="alert.status != 0">
                      <h4 class="pt-3 text-center" style="color:#e91e63;">{{ alert.message }}</h4>
                    </template>
                    <b-button ref="containerButton" class="w-100 btn btn-block btn-gradient-success btn-lg font-weight-medium auth-form-btn" style="background-color:#d2ddf7;" variant="info" @click="onDiscount">Voucher</b-button>                                        
                    <b-button ref="containerButton" class="w-100 btn btn-block btn-gradient-primary btn-lg font-weight-medium auth-form-btn" style="background-color:#d2ddf7;" variant="info" @click="onNext">Lanjut</b-button>                    
                    <!-- <router-link class="btn btn-block btn-gradient-primary btn-lg font-weight-medium auth-form-btn" to="/">SIGN IN</router-link> -->
                  </div>
                </form>
              </div>
            </div>
          </div>
      </div>
      <!-- content-wrapper ends -->
      </div>
      <!-- page-body-wrapper ends -->
    </div>
  </section>

</template>

<script>
import axios from 'axios'
import packageStore from "@/packages/stores"
import packageGlobals from "@/packages/globals"
export default {
  name: 'login',
  data(){
    return{
      passwordHidden: false,
      params:{
        uuid: this.$route.params.uuid
      },
      unitDimension : "cm",
      cubicM: "\u33A5",
      errorMassage:0,
      form:{
        route: '',
        route_title: 'pengiriman_paket',        
        uuid_payment_methods: '',
        created_by: localStorage.getItem("uuid"),
      },
      alert:{
        status: 0,
        message:'',
      },
      data: [],
      dataOption1: [],
      optSelect: '',
      optionsRadio: [],
      selected: 'first', 
    }
  },
  methods:{
    normalDateTime: (dateData) => packageGlobals.normalDateTime(dateData),        
    getPaymentMethod: function () {
      // let loader = this.$loading.show({
      //   container: this.fullPage ? null : this.$refs.containerMain,
      //   canCancel: false,
      //   onCancel: this.onCancel,
      // })

      packageGlobals.configAxios.headers["Access-Control-Allow-Methods"] = "GET"
			axios.get(`${packageStore.mainApi}/api/web/master/payment-method?search=-&page=1&limit=999`,packageGlobals.configAxios)      
			.then((response) => {
        // this.dataOptionServices = 
        let opt = response.data.result.data
        opt.forEach(element => {
          let pushData = {
            id : element.uuid,
            label : element.name
          }
          this.dataOption1.push(pushData)
        });
			})							
    },
    getData: function (uuid) {
      packageGlobals.configAxios.headers["Access-Control-Allow-Methods"] = "GET"
			axios.get(`${packageStore.mainApi}/api/web/operational/antar-barang/${uuid}`,packageGlobals.configAxios)
			.then((response) => {
        this.form.route = '/operasional/antar-barang/pengiriman-paket/'+this.params.uuid
        this.form.uuid_payment_methods = response.data.result.uuid_payment_methods                        
        this.data = response.data.result
			})      
    },    
    onDiscount()
    {
      this.$router.push(`/operasional/antar-barang/voucher/${this.params.uuid}`)
    },
    // onNext(evt) {
    onNext() {      
      this.form.uuid_payment_methods = this.optSelect['id']
      console.log(this.form);
      packageGlobals.configAxios.headers["Access-Control-Allow-Methods"] = "PUT"
      axios.put(`${packageStore.mainApi}/api/web/operational/antar-barang/${this.params.uuid}`,this.form)
      .then((response) => {
        this.data = response
        this.$router.push(`/operasional/antar-barang/pembayaran/${this.params.uuid}`)
      })
    }
  },
  mounted() {
		this.getPaymentMethod()
    this.getData(this.params.uuid)        
    // this.getShippingMethod('-',1,999)    
  }  
}
</script>
<style>
.breadcrumb-item + .breadcrumb-item::before
{
	padding-top: 1px;
}

.breadcrumb-item + .breadcrumb-item
{
	padding-top: 8px;
}

.table.table-borderless tr, .table.table-borderless td, .table.table-borderless th
{
  white-space: pre-wrap;
}

.next-breadcrumb a
{
	padding-top: 0px;
}

.custom-control-label{
  padding-top: 4px;
  font-size: 16px!important;
}

.input-group-text{
  font-weight: bold;
}
</style>